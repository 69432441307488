<template>
  <div>
    <hero-bar>
      Cadastro cliente
      <!-- <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link> -->
    </hero-bar>
    <section class="section is-main-section" v-if="!enviado && isLoading === false">
      <card-component title="Formulário cliente" icon="ballot">
        <form @submit.prevent="cadastroCliente">
          <b-field label="Nome" horizontal>
            <b-input v-model="dadosCliente.Name"></b-input>
          </b-field>
          <b-field label="Email" horizontal>
            <b-input v-model="dadosCliente.Email" type="email"></b-input>
          </b-field>
          <b-field label="Telefone" horizontal>
            <the-mask v-model="dadosCliente.NumberPhone" class="input"  :mask="['(##) ####-####', '(##) #####-####']" />
          </b-field>
          <hr />
          <b-field
            label="CNPJ"
            message="Adicione um ou mais CNPJs para esse cliente"
            horizontal
          >
            <div class="control" v-if="ListaCNPJs">
                <div v-for="(doc, index) in ListaCNPJs" class="field" :key="index">
                  <b-field grouped>
                    <b-field expanded label="Descrição">
                      <b-input v-model="doc.Descricao"></b-input>
                    </b-field>
                    <b-field expanded label="CNPJ">
                      <the-mask class="input" v-model="doc.CNPJ" :mask="['##.###.###/####-##']" />
                    </b-field>
                  </b-field>
                  <hr>
                </div>
              <b-button type="is-primary is-light" @click="AddCNPJ">Adicionar CNPJ</b-button>
            </div>
          </b-field>
          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary" :loading="isLoading">Cadastrar</b-button>
              </div>
              <!-- <div class="control">
                <b-button type="is-primary is-outlined" @click="reset"
                  >Reset</b-button
                >
              </div> -->
            </b-field>
          </b-field>
        </form>
      </card-component>
    </section>

    <section class="section has-text-centered" v-if="enviado && resposta !== null">
      <h1 class="title">Cliente cadastrado com sucesso!</h1>
      <h2 class="subtitle">Veja mais informações sobre o cliente cadastrado</h2>

      <router-link :to="{ name:'clientes' }" class="button is-medium ">
        <b-icon icon="arrow-left" size="is-medium"/>
        <p>Ir para lista de clientes</p>
      </router-link>

      <router-link :to="{ name:'cliente.detalhes', params: { id: resposta.IDCliente } }" class="button is-medium is-primary ml-4">
        <p>Ir para detalhe do cliente</p>
        <b-icon icon="arrow-right" size="is-medium"/>
      </router-link>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { cadastroCliente, notificationError } from '@/core'
export default {
  name: 'CadastroCliente',
  components: {
    HeroBar,
    CardComponent
  },
  data () {
    return {
      isLoading: false,
      dadosCliente: {},
      ListaCNPJs: [
        {
          Descricao: null,
          CNPJ: null
        }
      ],
      resposta: null,
      enviado: false
    }
  },
  methods: {
    AddCNPJ () {
      this.ListaCNPJs.push({
        Descricao: null,
        CNPJ: null
      })
    },
    cadastroCliente () {
      this.isLoading = true
      this.enviado = true
      this.dadosCliente.ListaCNPJs = this.ListaCNPJs
      cadastroCliente(this.dadosCliente)
        .then(res => {
          this.isLoading = false
          this.resposta = res
        })
        .catch(() => {
          this.isLoading = false
          this.enviado = false
          notificationError(this.$buefy.notification)
        })
    }
  }
}
</script>
